<template>
  <v-combobox
    v-model="emailChips"
    v-model:search="emailInput"
    class="email-chips-input"
    :label="label"
    :hint="hint"
    :delimiters="[',', ';', ' ']"
    append-inner-icon="custom:mail"
    autocomplete="off"
    closable-chips
    chips
    multiple
    :rules="[validateEmails(emailChips)]"
    variant="filled"
    :counter="maxEmails ? maxEmails : false"
    data-cy="addMembersEmailsInput"
    :placeholder="placeholder"
  >
    <template #chip="data">
      <v-chip
        closable
        close-icon="custom:cancel"
        :color="validateEmails([data.item.value]) === true ? '#026fe6' : 'red'"
        :variant="!(validateEmails([data.item.value]) === true) ? 'flat' : 'tonal'"
        size="default"
        @click:close="removeEmail(data.item.value)"
      >
        {{ data.item.value }}
      </v-chip>
    </template>
  </v-combobox>
</template>

<script lang="ts">
import type { PropType } from 'vue'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    maxEmails: {
      type: Number,
      default: null,
    },
    allowEmptyInput: {
      type: Boolean,
      default: false,
    },
    presetEmailList: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup() {
    const { isEmail } = useValidation()

    return {
      isEmail,
    }
  },
  data() {
    return {
      emailChips: this.presetEmailList || [],
      emailInput: '',
      fullEmailList: [] as string[],
      emailsAreValid: false,
    }
  },
  watch: {
    // Addresses the case of a user who has entered email
    // but not yet caused the email to turn into a chip
    emailInput(newEmailInput: string): void {
      const validEmailInInput = this.validateEmails([newEmailInput]) === true
      const fullEmailList = validEmailInInput ? [...this.emailChips, newEmailInput] : this.emailChips
      this.fullEmailList = fullEmailList
      this.checkEmailListValidity()
      if (validEmailInInput) this.emitData()
    },
    emailChips(emailChips: string[]): void {
      this.fullEmailList = emailChips
      this.checkEmailListValidity()
      this.emitData()
    },
    fullEmailList(): void {
      this.checkEmailListValidity()
    },
  },
  mounted() {
    // Code that will run only after the entire view has been rendered
    void this.$nextTick().then(() => {
      // This function is an ugly hack to replace the listener @paste="onPasteEmails" in the emails v-combobox
      // Since Vuetify 2.5.x it seems v-combobox no longer supports @paste
      const target = document.querySelector('.email-chips-input .v-field__input input')
      if (target) {
        target.addEventListener('paste', ((event: ClipboardEvent) => {
          event.preventDefault()
          this.onPasteEmails(event)
        }) as EventListener)
      }
    })
  },
  methods: {
    removeEmail(item: string): void {
      this.emailChips.splice(this.emailChips.indexOf(item), 1)
      this.emailChips = [...this.emailChips]
      this.fullEmailList = this.emailChips
      if (this.emailsAreValid) this.emitData()
    },
    validateEmails(emails: string[]): boolean | string {
      return emails.every(this.isEmail) || this.$t('global.email_invalid')
    },
    onPasteEmails(event: ClipboardEvent): void {
      // Get pasted contents
      const paste = (event.clipboardData || window.clipboardData)?.getData('text')
      // Split pasted string and remove empty elements
      const pastedEmails = paste?.split(/,| |;/).filter(Boolean) ?? []
      // Add pasted emails to the current email list, ensuring no duplicates
      const emailSet = new Set([...this.emailChips, ...pastedEmails])
      this.emailChips = Array.from(emailSet.values())
    },
    emitData(): void {
      if (this.emailsAreValid) this.$emit('setEmails', this.fullEmailList)
      else this.$emit('setEmails', [])
    },
    reset(): void {
      this.emailChips = []
      this.emailInput = ''
    },
    checkEmailListValidity(): void {
      const oneEmailExists = this.fullEmailList.length > 0
      const allChipsAreValid = this.validateEmails(this.emailChips) === true
      const invalidInputExists = this.emailInput?.length > 0 && this.validateEmails([this.emailInput]) !== true
      const tooManyEMails = this.maxEmails ? this.fullEmailList.length > this.maxEmails : false

      this.emailsAreValid =
        (oneEmailExists || this.allowEmptyInput) && allChipsAreValid && !invalidInputExists && !tooManyEMails

      this.$emit('setEmailValidity', this.emailsAreValid)
    },
  },
})
</script>

<style lang="sass">
.email-chips-input
  label + .v-select__selections
    padding-top: 28px !important
    padding-bottom: 5px

  .v-input__append-inner
    align-self: center
    margin-top: 0px
    .v-icon
      transform: none !important

  .v-chip
    font-weight: 400
    &.v-chip.v-chip--size-small
      padding: 0px 8px 0px 12px
      height: 30px
      .v-chip__close
        margin-inline-start: 10px

.v-field
  &__append-inner
    color : $c-primary
</style>
